.noPadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.reducePadding{
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}

.backGrayBorder{
    background-color: #dfdfdfa3;
    border-radius: 5px;
    padding: 5px;
    height: 100%;
}

.reset-bail{
    &:hover{
        transform: scale(1.2);
        cursor: pointer;
    }
}