.closeButton{
    cursor: pointer;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    z-index: 5;
    height: 30px;
    width: 30px;
    svg{
        transition: all ease .5s;
        width: 25px;
        height: 25px
    }
}
.closeButton:hover{
    svg{
        width: 30px;
        height: 30px
    }
}