@import url('https://fonts.googleapis.com/css2?family=Corinthia&display=swap');

.menuItem{
    cursor: pointer;
    height: 220px;
    margin-top: 5vh;
    transition: transform .1s;
    transition: all ease .2s;
    .container-text{
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        width: 100%;
        height: 100%;
        h2{
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0;
            color: #6e6d6a;
            z-index: 1;
            position: absolute;
            bottom: 35px;
            background-color: white;
            padding: 5px;
        }
        svg{
            color: #B7A57E !important;
            position: absolute;
            height: 150px;
            width: 150px;
            top: 0;
            z-index: 0;
        }
    }
}

.disconect-button{
    text-decoration: underline;
    color: #8F8D8E !important;
    &:hover{
        cursor: pointer;
    }
}

.welcome-title{
    font-size: 3rem;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    color: #8F8D8E !important;
}

.menuUser{
    margin-bottom: 5vh;
}

.small-logo{
    width: 100%;
    height: auto;
}

.menuItem:hover {
    transform: scale(1.02);
}