@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import "./pages/home/Home.scss";
@import "./components/footer/Footer.scss";
@import "./components/admin/apartments/Apartments.scss";

$primary :  #A42141;
$secondary: #B7A57E;
$sucess:#19C1A8;
$error: #E30910;
* {
  font-family: Montserrat, sans-serif;
}

html {
  min-height: 100%;
  overflow-y: scroll;
  height: 100%;
  font-display: auto;
}

body {
   min-height: 100%;
} 

#root{
  max-width: 100%;
}

.cartridge{
  border-radius: 5px;
  background-color: rgba(0,0,0,.03);
  border: 1px solid rgba(0,0,0,.1);
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus, .btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
}
.btn-primary, .btn-primary:visited, .btn-primary:focus, .btn-primary:active{
  background-color: white  !important;
  color: black !important;
  border: solid 1px $secondary !important;
  display: inline-block;
  border-radius:0px;
  };
  
  .btn-berry{
    background-color: $primary  !important;
    color: white !important;
    display: inline-block;
  }

  .whiteSVG{
    svg{
      color: white;
    }
  }

  .redSVG{
    height: 100%;
    svg{
      color: #A42141;
    }
  }

  .goldenHover{
    background-color: white !important;
    color: black !important;
    border: solid 1px #B7A57E !important;
    svg{
      color: black;
    }
  }

  .goldenHover:hover{
    background-color: #B7A57E !important;
  }


  .whiteHover:hover{
    background-color: white !important;
    color: $primary !important;
    border: solid 1px $primary !important;
  }

  .hover-primary{
    margin-top: 1vh;
    background-color: $primary !important;
    color: white !important;
    &:hover{
      background-color: white !important;
      color: $primary !important;
      border-color: $primary !important;
    }
  }

.container{
  background: white;
  height: 75vh;
}
  .row-button-plus-right{
    border-width: 1px !important;
  }

.backdropCountry{
  backdrop-filter: blur(100px); /* Applique un flou important */
  background-color: rgba(0, 0, 0, 0.7); /* Couleur sombre semi-transparente */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
}

.backdrop{
    z-index: 20000;
  } 

  .backdropThird{
    z-index: 20001;
  } 

  .backdropFourth{
    z-index: 20003;
  } 

  .backdropFifth{
    z-index: 20004;
  } 

  .btn-secondary, .btn-secondary:visited, .btn-secondary:focus, .btn-secondary:active{
    background-color: $secondary !important;
    border-color: lighten($secondary, 5);
    display: inline-block;
    color: #FFF;
      &:hover{
        background-color: darken($secondary, 5) !important;
      };
    };
    
  .sticky-top{
    z-index: 100;
  }
  
  .transparent{
    background-color: transparent;
  }
  
.test{
  text-align: center;
}

.contactContainer{
  align-items: center;
  justify-Content: center;
}

.contactContainer .card{
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadowForCard{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

h2 {
  text-align: center;
  color:grey;
}

.btn-rounded{
  border-radius: 5px;
}

//for Tablets

@media screen and (max-width: 991px) { 
  .contactContainer .card{
    text-align: center;
   }
}

#dropdown-nav-search{
  border-radius: 5px 0px 0px 5px;
}

#dropdown-nav{
    border-radius: 5px;
}

html{
  max-height:100%;
  overflow-y:inherit;
}

.centered{
  display: grid;
  place-items: center;
}

