.contact-list{
    width: 100%;
    height: 80%
}
.row{
    margin-left: 0;
    margin-right: 0;
}
.centerPagination{
    display: grid;
    place-items: center;
}

