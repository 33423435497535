#formSearchAdmin{
    text-align: center;
    margin-top: auto;
}

.bookingDetails:hover{
    color: white !important;
    background-color: #A42141 !important;
}

.apartmentReservationCardPart{
    padding: 0.7vh 0.7vw 0.7vh 0.7vw;
}


.CreateBookingButton{
    margin-bottom: 30px !important;
    margin-top: -20px !important;
 }


//cards
.apartmentRef{
    font-weight: bold;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.apartmentText {
    margin-bottom: 0px !important;
}

.paginationBooking{
    position: fixed;
    top: 88vh;
    left: 66.6%;
    transform: translate(-50%, -50%)
}

.size-img-app-booking{
    width: 100%;
}

.apartmentReservationCard{
    margin-bottom: 5px;
}


.addPaymentButton{
     margin: 1vh !important;
}

.verticalCenteredForm{
    position: relative;
    margin-top: 15vh
}

.verticalCenteredBadge {
    margin: auto;
}

.invoiceRodal {
    margin-top: 5vh !important;
    overflow-y: scroll !important;
    position: flex;

}

.invoiceRodal.modalBody {
    overflow-y: scroll !important;
}

//Tablet
@media screen and (max-width: 991px) {
    .paginationBooking{
        position:relative !important;
        transform: translate(-66%, -1000%);
    }
    .searchBooking{
        margin-top: 7vh;
    }
    .CreateBookingButton{
        border-radius: 25px;
     }
}

//Mobiles

@media screen and (max-width: 576px) {
    .CreateBookingButton{
        border-radius: 25px;
        margin-right: 5vw !important;
        margin-left: 5vw !important;
     }
     .paginationBooking{
        position:relative !important;
    }
}





