// class Booking à payer

// jointure des reservations

// à payer
.end-book-need-begin-book-need{
    background: linear-gradient(135deg, #9e0a00 50%, #9e0a00 50%);
}

.end-book-need-begin-book-wait{
    background: linear-gradient(135deg, #9e0a00 50%, gray 50%);
}

.end-book-need-begin-book-first{
    background: linear-gradient(135deg, #9e0a00 50%, yellow 50%);
}

.end-book-need-begin-book-payed{
    background: linear-gradient(135deg, #9e0a00 50%, green 50%);
}

// trop perçu
.end-book-exed-begin-book-need{
    background: linear-gradient(135deg, purple 50%, #9e0a00 50%);
}

.end-book-exed-begin-book-wait{
    background: linear-gradient(135deg, purple 50%, gray 50%);
}

.end-book-exed-begin-book-first{
    background: linear-gradient(135deg, purple 50%, yellow 50%);
}

.end-book-exed-begin-book-payed{
    background: linear-gradient(135deg, purple 50%, green 50%);
}

// en attente

.end-book-wait-begin-book-need{
    background: linear-gradient(135deg, gray 50%, #9e0a00 50%);
}

.end-book-wait-begin-book-wait{
    background: linear-gradient(135deg, gray 50%, gray 50%);
}

.end-book-wait-begin-book-first{
    background: linear-gradient(135deg, gray 50%, yellow 50%);
}

.end-book-wait-begin-book-payed{
    background: linear-gradient(135deg, gray 50%, green 50%);
}

// accompte versé

.end-book-first-begin-book-need{
    background: linear-gradient(135deg, yellow 50%, #9e0a00 50%);
}

.end-book-first-begin-book-wait{
    background: linear-gradient(135deg, yellow 50%, gray 50%);
}

.end-book-first-begin-book-first{
    background: linear-gradient(135deg, yellow 50%, yellow 50%);
}

.end-book-first-begin-book-payed{
    background: linear-gradient(135deg, yellow 50%, green 50%);
}

.end-book-first-begin-cal-ca{
    background: linear-gradient(135deg, yellow 50%, SteelBlue 50%);
}

.end-book-first-begin-cal-prop{
    background: linear-gradient(135deg, yellow 50%, LightSeaGreen 50%);
}
// payé 

.end-book-payed-begin-book-need{
    background: linear-gradient(135deg, green 50%, #9e0a00 50%);
}

.end-book-payed-begin-book-wait{
    background: linear-gradient(135deg, green 50%, gray 50%);
}

.end-book-payed-begin-book-first{
    background: linear-gradient(135deg, green 50%, yellow 50%);
}

.end-book-payed-begin-book-payed{
    background: linear-gradient(135deg, green 50%, green 50%);
}
.end-book-payed-begin-book-exed{
    background: linear-gradient(135deg, green 50%, purple 50%);
}

// debut

.begin-book-need{
    background: linear-gradient(135deg, white 50%, #9e0a00 50%);
}

// en cour

.in-progress-book-need{
    background: #9e0a00;
}

// fin

.end-book-need{
    background: linear-gradient(135deg, #9e0a00 50%, white 50%);
}

.end-book-need-begin-book{
    background: linear-gradient(135deg, #9e0a00 50%, #9e0a00 50%);
}

.end-book-need-begin-cal-prop{
    background: linear-gradient(135deg, #9e0a00 50%, LightSeaGreen 50%);
}

.end-book-need-begin-cal-ca{
    background: linear-gradient(135deg, #9e0a00 50%, SteelBlue 50%);
}

//-------------------------------------------------//
// class Booking accompte versé

// debut

.begin-book-first{
    background: linear-gradient(135deg, white 50%, yellow 50%);
}

// en cour

.in-progress-book-first{
    background: yellow;
}

// fin

.end-book-first{
    background: linear-gradient(135deg, yellow 50%, white 50%);
}

.end-book-begin-book-first{
    background: linear-gradient(135deg, yellow 50%, yellow 50%);
}

.end-book-begin-cal-prop-first{
    background: linear-gradient(135deg, yellow 50%, LightSeaGreen 50%);
}

.end-book-begin-cal-ca-first{
    background: linear-gradient(135deg, yellow 50%, SteelBlue 50%);
}

//-------------------------------------------------//

// class Booking trop perçu

// debut

.begin-book-exed{
    background: linear-gradient(135deg, white 50%, purple 50%);
}

// en cour

.in-progress-book-exed{
    background: purple;
}

// fin

.end-book-exed{
    background: linear-gradient(135deg, purple 50%, white 50%);
}

.end-book-exed-begin-book{
    background: linear-gradient(135deg, purple 50%, yellow 50%);
}

.end-book-exed-begin-cal-prop{
    background: linear-gradient(135deg, purple 50%, LightSeaGreen 50%);
}

.end-book-exed-begin-cal-ca{
    background: linear-gradient(135deg, purple 50%, SteelBlue 50%);
}

//-------------------------------------------------//

// class Booking payé

// debut

.begin-book-payed{
    background: linear-gradient(135deg, white 50%, green 50%);
}

// en cour

.in-progress-book-payed{
    background: green;
}

// fin

.end-book-payed{
    background: linear-gradient(135deg, green 50%, white 50%);
}

.end-book-payed-begin-book{
    background: linear-gradient(135deg, green 50%, green 50%);
}

.end-book-payed-begin-cal-prop{
    background: linear-gradient(135deg, green 50%, LightSeaGreen 50%);
}

.end-book-payed-begin-cal-ca{
    background: linear-gradient(135deg, green 50%, SteelBlue 50%);
}

//-------------------------------------------------//

// class Booking payé

// debut

.begin-book-wait{
    background: linear-gradient(135deg, white 50%, gray 50%);
}

// en cour

.in-progress-book-wait{
    background: gray;
}

// fin

.end-book-wait{
    background: linear-gradient(135deg, gray 50%, white 50%);
}

.end-book-wait-begin-book{
    background: linear-gradient(135deg, gray 50%, gray 50%);
}

.end-book-wait-begin-cal-prop{
    background: linear-gradient(135deg, gray 50%, LightSeaGreen 50%);
}

.end-book-wait-begin-cal-ca{
    background: linear-gradient(135deg, gray 50%, SteelBlue 50%);
}

//-------------------------------------------------//
// class blocage CA

// debut

.begin-cal-ca{
    background: linear-gradient(135deg, white 50%, SteelBlue 50%);
}

// en cour

.in-progress-cal-ca{
    background: SteelBlue;
}

// fin 

.end-cal-ca-begin-cal-ca{
    background: linear-gradient(135deg, SteelBlue 50%, SteelBlue 50%);
}

.end-cal-ca-begin-cal-prop{
    background: linear-gradient(135deg, SteelBlue 50%, LightSeaGreen 50%);
}

.end-cal-ca{
    background: linear-gradient(135deg, SteelBlue 50%, white 50%);
}

//résa à payer
.end-cal-ca-begin-book-need{
    background: linear-gradient(135deg, SteelBlue 50%, #9e0a00 50%);
}


.end-cal-ca-begin-book-wait{
    background: linear-gradient(135deg, SteelBlue 50%, gray 50%);
}


.end-cal-ca-begin-book-first{
    background: linear-gradient(135deg, SteelBlue 50%, yellow 50%);
}


.end-cal-ca-begin-book-payed{
    background: linear-gradient(135deg, SteelBlue 50%, green 50%);
}

.end-cal-ca-begin-book-exed{
    background: linear-gradient(135deg, SteelBlue 50%, purple 50%);
}

//-------------------------------------------------//

// class Blocage prop

// debut

.begin-cal-prop{
    background: linear-gradient(135deg, white 50%, LightSeaGreen 50%);
}

// en cour

.in-progress-cal-prop{
    background: LightSeaGreen;
}

// fin 
.end-cal-prop{
    background: linear-gradient(135deg, LightSeaGreen 50%, white 50%);
}

.end-cal-prop-begin-cal-prop{
    background: linear-gradient(135deg, LightSeaGreen 50%, LightSeaGreen 50%);
}

// résa à payer
.end-cal-prop-begin-book-need{
    background: linear-gradient(135deg, LightSeaGreen 50%, #9e0a00 50%);
}

.end-cal-prop-begin-book-first{
    background: linear-gradient(135deg, LightSeaGreen 50%, yellow 50%);
}


.end-cal-prop-begin-book-wait{
    background: linear-gradient(135deg, LightSeaGreen 50%, gray 50%);
}


.end-cal-prop-begin-book-payed{
    background: linear-gradient(135deg, LightSeaGreen 50%, green 50%);
}

.end-cal-prop-begin-book-exed{
    background: linear-gradient(135deg, LightSeaGreen 50%, purple 50%);
}

.end-cal-prop-begin-cal-ca{
    background: linear-gradient(135deg, LightSeaGreen 50%, SteelBlue 50%);
}

// class de mise en forme

th{
    text-align: center;
}

.stickyTopMonth{
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
}
.stickyTopDay{
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 25px;
}

.hightLight{
    filter: brightness(70%);
}

table {     
    width: 100%;
    overflow-y: auto;
}

.tdCalendar {
    width: 10px;
    border: 1px solid;
}

.thBordered{
    width: 10px;
    border: 1px solid;
}