$secondary: #218EAE;
pre{
    font-family: Montserra;
    font-size: 1.2rem;
    white-space: pre-wrap;
    height: 30vh;
    overflow: hidden;
}

.addFavShowApart:hover{
    g g{
        fill: white;
    }
    path{
        stroke: #B7A57E;
        fill: #B7A57E;
    }
}

.noDisplayforLarge{
    display: none;
}

.headerShowAppart {
    h1{
        font-weight: bold;
    }
    div{
        svg{
            margin-left: 15px;
            margin-right: 15px;
            cursor: pointer;
        }
    }
}

.shareButton{
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.shareList{
    bottom: -50px;
    right: 0px;
    width: 100%;
    height: 32px;
    background-color: transparent;
}

.transition-easy{
    transition: all ease 1s;
}

.showAppartRef h2{
    font-weight: bold;
    text-align: left;
    color: black;
}

.marginH2Mobile{
    margin-top: 10px;
    .nightPrice{
        font-size: 1.5rem;
        text-align: center;
        strong{
            color: #B7A57E;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}

.marginH2Desktop{
    margin-top: 3vh;
    margin-bottom: 3vh;
    .nightPrice{
        font-size: 1.5rem;
        text-align: center;
        strong{
            color: #B7A57E;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}

.showAppartRef{
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.reserverButtonEnabled{
    color:#FFF !important;
    background-color: #B7A57E !important;
    border: 1 solid #FFFFFF !important;
    transition: all .2s ease;
  }

  .reserverButtonEnabled:hover{
    font-size: 1.35rem;
  }

  .reserverButtonDisabled{
    color:#B7A57E !important;
    background-color: white !important;
    border: 1 solid #FFFFFF !important;
  }



.noBorderPadding{
    padding-left: unset !important;
    padding-right: unset !important;
}

.collapsePre{
    -moz-transition: height .5s ease;
    -ms-transition: height .5s ease;
    -o-transition: height .5s ease;
    -webkit-transition: height .5s ease;
    transition: height .5s ease;
    height: 100%;
}

.uncollapsePre{
    -moz-transition: height 1s ease;
    -ms-transition: height 1s ease;
    -o-transition: height 1s ease;
    -webkit-transition: height 1s ease;
    transition: height 1s ease;
    height: 30vh;
    overflow: hidden;
}

.collapsePre:after{
    height: 100%;
}

.appartmentPicturesCaroussel{
    max-height: 60vh !important;
}

.listRoom{
    width: 100%;
    height: 25vh;
    display: flex;
    overflow-x: scroll;
}

::-webkit-scrollbar-thumb {
    position: absolute;
    background-color: lightgray;
    border: 7px solid transparent;
    border-radius: 10px;
    background-clip: padding-box; 
}

::-webkit-scrollbar {
    width: 20px;
    height: 20px;
}

.end-group{ 
    margin-bottom: 5vh;
    margin-top: 2vh;
    margin-top: 2vh;
    padding-left: 3vw;
    padding-Right: 3vw;
}

.begin-group{
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.begin-group .row{
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.begin-group svg{
    width: 50px;
    height: 50px;
}

.end-group svg{
    width: 40px;
    height: 40px;
}
.end-group h4{
    font-weight: bold;
}

.end-group .col-6{
    margin-bottom: 1vh;
}

.justifyCenter{
    justify-content: center;
}

.caroussel-skel{
    width: 80% !important;
    height: 60vh !important;
}

.title-skel{
    width: 20% !important;
}

.longText{
    width: 90%
}

.mediumText{
    width: 50%
}

.text-book{
    margin-top: unset;
    margin-bottom: unset;
}

.width{
    width: 50%;
}

.shortText{
    width: 20%
}

.badge-skel{
    margin: 1vh !important;
    width: 5% !important;
}

.bookCardDekstop{
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    margin-bottom: 3.5rem;
    .bookCardDekstopChild{
        border: solid 1px lightgray;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
        padding: 1rem;
        background-color: black;
        margin-left: auto;
        margin-right: auto;
    }
    .spinner-border{
        color: white;
    }
}

.maxNight{
    color: white;
}

.date-range-book{
    background-color: black;
    height: 150px;
    display: flex;
    align-items: center;
    width: 100%;
    input{
        border: none;
        color: transparent;
        padding: unset;
        background-color: transparent;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    svg{
        height: 21px;
        width: 21px;
        position: absolute;
        bottom: 0;
        right: 21px;
    }
    .inputValueWithDate{
        color: #b7a57e;
        padding: unset;
        margin: unset;
        font-size: 3rem;
        font-weight: 900;
        width: auto;
        small{
            font-size: 1rem;
            color: white;
        }
    }
    .inputValueWithoutDate{
        text-align: center;
        color: #b7a57e;
        padding: unset;
        margin: unset;
        font-size: 1.5rem;
        font-weight: 900;
        width: auto;
    }
}
.select-book-nb{
    height: 100px;
    width: 100%;
    div div{
        color: white;
    }
}

.inputTitle{
    margin: unset;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
}

.bottomInformationCard{
    box-shadow: 0px 6px 6px #00000029;
    padding: 15px;
    height: 100%;
    p{
        color: #8f8d8e;
        font: normal normal bold 15px/20px Montserrat;
        text-align: center;
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 20px; 
    }
    svg{
        height: 58px;
        width: 90px;
    }
}

.btnDoc{
    .btn svg{
            height: 15px;
            width: 20px
        }
}

@media (max-width:1100px){
    .select-book-nb{
        height: 120px;
    }
}

@media screen and (max-width:  991px){
    .width{
        width: 80%;
    }
    .headerShowAppart h1{
        font-size: 2rem;
    }

    .bookCardDekstop{
        all: unset;
        .bookCardDekstopChild{
            all: unset;
        }
    }

    .bookCardMobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: unset;
        bottom: 0px !important;
        left: 0px !important;
        position: fixed !important;
        right: 0px !important;
        min-height: 110px;
        max-height: 180px;
        width: 100%;
        z-index: 500;
        background-color: white;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 15px 15px;
        .spinner-border{
            color: black;
        }
    }

    .maxNight{
        color: black;
    }

    .noDisplay{
        display: none !important;
    }
    
    .noDisplayforLarge{
        display: unset;
    }

}
@media screen and (max-width:767px) {
    .width{
        width: 100%;
    }
    
    .headerShowAppart h1{
        font-size: 1.5rem;
    }

    .headerShowAppart {
        div{
            .svgDisplay{
                height: 30px;
            }
        }
    }

    .bottomInformationCard{
        p{
            font: normal normal bold 12px/15px Montserrat;
        }
    }
    .nightPrice{
        font-size: 1.2rem;
    }
}