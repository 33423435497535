.slider-create-right {
    position: absolute;
    transform: translateX(200%);
    -webkit-transform: translateX(200%);
    -webkit-transition: opacity .6s;
    transition: opacity .6s;
    opacity: 0;
    width: 0;
    overflow-y: hidden;
}
.slider-list-left {
    position: absolute;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -webkit-transition: opacity .6s;
    transition: opacity .6s;
}

.slide-in-create-right {
    animation: slide-in-create-right .9s forwards;
    -webkit-animation: slide-in-create-right .9s forwards;
    overflow-y: visible !important;
    width: 100%;
    height: 100%;
    filter: alpha(opacity=50);
        opacity: 1;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -ms-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
}
    
@keyframes slide-in-create-right {
    0% {transform: translateX(200%); 
        opacity: 0;
        width: 0%;}
    10%{
        width: 100%;
    }
    100% { 
            overflow-y: scroll !important;
            transform: translateX(0%);
            transform: opacity 1; 
            width: 100%;}
            
}

@-webkit-keyframes slide-in-create-right {
    0% { -webkit-transform: translateX(200%); 
        -webkit-transform: opacity 0;
        -webkit-transform: width 0%;
    }
    10%{
        -webkit-transform: width 100%;
    }
    100% { 
        overflow-y: scroll;
        -webkit-transform: translateX(0%); 
        -webkit-transform: opacity 1;
        -webkit-transform: width 100%;
    }
}

.slide-in-list-left {
    animation: slide-in-list-left 1s forwards;
    -webkit-animation: slide-in-list-left 1s forwards;
    filter: alpha(opacity=50);
        opacity: 1;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -ms-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
}

.slide-out-list-left {
    animation: slide-out-list-left 1s forwards;
    -webkit-animation: slide-out-list-left 1s forwards;
    display: none;
    filter: alpha(opacity=50);
        opacity: 0;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -ms-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
}
    
@keyframes slide-in-list-left {
    0% { transform: translateX(-100%);
        transform: opacity 0; }
    100% { transform: translateX(0%);
            transform: opacity 1; }
}

@-webkit-keyframes slide-in-list-left {
    0% { -webkit-transform: translateX(-100%);
        -webkit-transform: opacity 0; }
    100% { -webkit-transform: translateX(0%); 
        -webkit-transform: opacity 1;}
}
    
@keyframes slide-out-list-left {
    0% { transform: translateX(0%);
        transform: opacity 1; }
    100% { transform: translateX(-100%); 
        transform: opacity 0;}
}

@-webkit-keyframes slide-out-list-left {
    0% { -webkit-transform: translateX(0%);
        -webkit-transform: opacity 1; }
    100% { -webkit-transform: translateX(-100%); 
        -webkit-transform: opacity 0;}
}
.users{
    width: 100%;
    min-height: 100%;
    padding-bottom: 30px;
}

.padding-row{
    padding: 2vh;
}

.userSearchBody{
    margin-bottom: 0px !important;
}

.userSearchBar {
    border-radius: 0px 5px 5px 0px;
}

