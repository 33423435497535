.marginCard{
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
}

.margin-button{
    margin-left: 10%;
}

.margin-eye{
    margin-right: 5%;
}

.icon-button-sad{
    background-color: transparent;
    color:black;
}