.cookies{
    background-color: white;
    position: fixed !important;
    left: -500px;
    bottom: 5px;
    width: 365px;
    height: 250px;
    z-index: 50;
    transition: all ease .5s;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    .row{
        height: 100%;
    }
}

.slide-in-cookies{
    left: 5px !important;
}

.slide-out-cookies{
    left: -500px !important;
}

.accept{
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: rgb(252, 252, 252);
    }
}

.refuse{
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: rgb(252, 252, 252);
    }
}