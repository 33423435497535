.vacation-list{
    list-style: inside;
}

.needPicture{
    width: 100%;
}

.page-separator {
    margin: 50px 0px !important;
}

.service-box {
    position: relative;
    background: white;
    padding: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    margin-top: -50px; /* Monte la div au-dessus de l'image */
    z-index: 10; /* S'assure qu'elle reste au-dessus de l'image */
}

.end-separator {
    margin-top: 100px ;
}

.strongToGold{
    strong{
        color: #B7A57E;
        font-size: 105%;
    }
}

.agency-pictures{
    width: 100%;
    height: 30vh;
    margin-bottom:20px;
    background-position: center; 
    background-size: 100%, 40%, auto;
}

.centered{
    text-align:center !important;
}

.images-footer{
    width :100%;
    height:100%;
    margin-bottom:10px !important;
    padding:5px;
}
.images-footer-horizontal{
    width :100%;
    height:auto;
    margin-bottom:10px !important;
    padding:5px;
}
@media screen and (max-width:  991px) and (min-width: 767px) {
    .alignImage{
        display: flex;
    }
  }

  //For Mobile 

  @media screen and (max-width:767px) {
    .alignImage{
        display: flex;
    }
}
