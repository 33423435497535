.thumbnail {
    position: relative;
    -ms-zoom: 0.25;
    -moz-transform: scale(0.25);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.25);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.25);
    -webkit-transform-origin: 0 0;
  }
  .thumbnail iframe {
    width: 1440px;
    height: 900px;
  }

  .thumbnail-container {
    width: calc(1440px * 0.25);
    height: calc(900px * 0.25);
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-align: center;
  }