$secondary: #218EAE;
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
.header-sticky{
    text-align: center;
    z-index:5000;
    position: -webkit-sticky !important;
    position: sticky !important;
    top:0;
  }

.navItems {
    display: block;
    position: relative;
    padding: 0.2em 0;
    color: #8F8D8E !important;
    font: normal normal 600 1vw Montserrat;
}

.logo-Navbar{
    max-width: 20vw;
    max-height: 80px; 
    margin: 0 100px 0 0; 
  }

.positionLogo{
    position: absolute;
    left: 50%;
    right: 50%;
    top :0;
}

.navbar-nav{
    width: 100%;
}

.magazine{
    position: relative;
    top: -6px;
    font-size: 1.7vw;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    color: #8F8D8E !important;
    text-decoration: none;
    font-weight: 300;
    width: auto;
}

.allreadyUnderlined{
    background: linear-gradient(to right, #B7A57E, #B7A57E);
    background-size: 60% 2px, 0 2px;
    background-position: 50% 100%, 0 100%;
    background-repeat: no-repeat;
    color: #B7A57E !important;

}

.navItems:hover {
    color: #B7A57E !important;
}

.navItems-magazine a{
    color: #b7a57e;
}

.navItems-favoris{ 
    width: 60px;
    height: 60px;
    color: #B7A57E;
    &:hover{
        path{
            fill: #B7A57E;
        }
    }

}

.navItemDropDownContent{
    position: absolute;
    width: 200px;
    box-shadow: 0px 11px 11px #00000029;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 100%)
}

.navItemDropDownContent li{
    text-decoration: none;
    padding: .5vh;
    width: 100%;
}

.navItemDropDownContent li a{
   color: #5f5f5f;
}
.navItemDropDownContent li p{
    color: #5f5f5f;
    margin: unset;
 }

.navItemDropDownContent li:hover{
    background-color: lightgray;
    cursor: pointer;
}

.logo-account{
    color: #B7A57E;
    width: 57px;
    height: 48px;
    cursor: pointer;
}

.logo {
    width: 100% !important;
    height: auto;
    margin:0px !important;
}

.HeaderButton{
    margin-right: 1vw !important;
}

.nav-Bar-linkWidth{
    width: 70%;
}

//   Mobile & tablets

  @media screen and (max-width:560px) {
    
    // Navbar + Header
    .positionLogo{
        top : 20vh;
    }

    .navbar-toggler{
        border: none !important;
        margin-top: 3vh; 
    }

    .logo-Navbar{
        display: none;
    }

    .logo-Navbar-Responsive{
        width: 100%;
    }

    .navItems{
        height: 7vh;
        font-size: 2vh;
        margin-right: 0;
    }

    .navItemDropDownContent{
        left: 50%;
    }

  }

 @media screen  and (max-width:990px) {
    .positionLogo{
        top : 23vh;
    }

    .navbar-toggler{
        border: none !important;
        margin-top: 3vh; 
    }

    .logo-Navbar{
        display: none;
    }

    .navItems{
        height: 7vh;
        font-size: 2vh;
        margin-right: 0;
    }

    .magazine{
        font-size: 3vh;
    }

    .allreadyUnderlined{
        background-position: 50% 70%, 0% 100%;
        background-size: 40% 2px, 0 2px;
    }

    .navItemDropDownContent{
        left: 35%;
    }
  }

  @media screen and (min-width:360px) and (max-width:530px) {
    
    .navItemDropDownContent{
        left: 20%;
    }

  }
 

