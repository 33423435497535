.Opinion{
    width: 100%;
    height: 80vh;
    margin-bottom: 5vh;
    margin-top: 5vh;
}


.CardOpinion{
    padding: 6%;
    position: relative;
    margin-left: auto;
    right: 10%;
    top: 15%;
    height: 70%;
    width: 35%;
    background-color: white;
    opacity: .96;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.Apostrophe{
    position: absolute;
    top: 10%;
    left: 10%;
    font:  var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) 99px/30px var(--unnamed-font-family-montserrat);
    font-size: 10vh;
    font-weight: 900;
    color: #B7A57E;
}

.CardOpinion .opinionContent{
    position: absolute;
    top: 20%;
    right: 15%;
    color: #8F8D8E;
    width: 70%;
    height: 60%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.opinionInfo{
    color: #8F8D8E;
    text-align: center;
}

.hoverDote:hover{
    cursor: pointer;
    -webkit-transform: scale(1.2)
}

.opinionDote{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90%;
    height: 10%;
    top: 85%;
    left: 5%;
    svg{
        margin: 1vh;
    }
}

.invisible{
    opacity: 0;
    transition: all 350ms;
}

.visible{
    opacity: 1;
    transition: all 350ms;
}


@media screen and (max-width:1200px) {

    .CardOpinion{
        width: 80%;
    }

    .CardOpinion .opinionContent{
        font-size: 1.3rem;
    }

    .Apostrophe{
        top: 2%;
    }
}

@media screen and (max-width:500px) {
    
    .CardOpinion .opinionContent{
        font-size: 1rem;
    }
    
}