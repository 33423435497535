.label-on-focus{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(15px, -20px) scale(0.75);
    transition: all ease .2s
}

.label-not-focus{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(15px, 2px) scale(1);
    transition: all ease .2s
}

.custom-input:focus-within .border-input{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.custom-input{
    position: relative;
    .border-input{
        border: 1px solid gray;
        border-radius: 5px;
        .input-focus{
            border-radius: 5px;
            border: unset;
            padding-left: 10px;
        }
        .input-focus:focus{
            outline: none !important;
        }
        .input-focus::-webkit-input-placeholder {
            color: unset;
        }
    }
    svg{
        position: absolute;
        right: 5px;
        top: 1px;
        color: gray;
        &:hover{
            cursor: pointer;
        }
    }
}